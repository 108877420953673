import { Box, Container } from '@chakra-ui/react';
import { AtAGlance } from 'components/modules/AtAGlance';
import { TextModule } from 'components/modules/TextModule';
import React, { FC } from 'react';

import { AtAGlance as AtAGlanceModel, ContentPage, TextModule as TextModuleModel } from '../../models';

export const hasAtAGlance = (content: ContentPage) => {
  if (
    content.elements.content.linkedItems[0]?.elements &&
    'atAGlance' in content.elements.content.linkedItems[0].elements
  )
    return content.elements.content.linkedItems[0].elements.atAGlance.linkedItems[0];
};

interface GlanceAndTextProps {
  textModuleModel: TextModuleModel;
  atAGlanceModel: AtAGlanceModel;
}

export const GlanceAndText: FC<GlanceAndTextProps> = ({ textModuleModel, atAGlanceModel }) => {
  return (
    <Box w='100vw'>
      <Container
        position={'relative'}
        display={'flex'}
        gap={{ base: 's', lg: 'l' }}
        justifyContent='space-between'
        flexDirection={{ base: 'column', lg: 'row' }}
        my={{ base: 'xs', lg: 'm' }}
        px={{ base: 'xs', lg: 'l' }}
      >
        <AtAGlance model={atAGlanceModel} />
        <Box w={{ base: '100%', md: '60%' }}>
          <TextModule withGlance model={textModuleModel} />
        </Box>
      </Container>
    </Box>
  );
};
