import { Box, Container } from '@chakra-ui/react';
import { emptyBody } from 'helpers/htmlResolver';
import Script from 'next/script';
import React, { FunctionComponent, useEffect } from 'react';
import { useCookies } from 'react-cookie';

interface CookieDeclarationProps {
  domainGroupId: string;
  cookiesInUseHeadlineHtml: string;
}

export const CookieDeclaration: FunctionComponent<CookieDeclarationProps> = ({
  cookiesInUseHeadlineHtml,
  domainGroupId,
}) => {
  const [cookie] = useCookies(['nonce']);
  useEffect(() => {
    const stylesheet = document.getElementById('CookieDeclarationStyleSheet');
    if (!stylesheet) {
      const cookieDeclarationStyleSheet = document.createElement('style');
      cookieDeclarationStyleSheet.setAttribute('id', 'CookieDeclarationStyleSheet');
      const css = `
        .CookieDeclaration {
          font-family: HCo Gotham Rounded Book;
          font-size: 16px;
          line-height: 24px;
          width: 100%;
          max-width: 940px;
          margin-left: auto;
          margin-right: auto;
        }
        .CookieDeclaration a { 
          color: #0098eb; 
        }
        .CookieDeclaration a:hover { 
          color: #007bbd; 
        }
        .CookieDeclaration a:visited { 
          color: #007bbd; 
        }

        #CookieDeclarationConsentIdAndDate span {
          word-break: break-word;
        }

        .CookieDeclaration .CookieDeclarationType {
          overflow-x: auto;
          max-width: calc(100vw - 40px);
          border: none;
          padding: 0;
        }

        .CookieDeclaration .CookieDeclarationTableCell {
          border: 1px solid #000;
        }

        .CookieDeclaration .CookieDeclarationTable {
          min-width: 700px;
        }

        @media (max-width: 600px) {
          .CookieDeclaration table.CookieDeclarationTable tr td:nth-child(n+5), .CookieDeclaration table.CookieDeclarationTable tr th:nth-child(n+5), .CookieDeclaration table.CookieDeclarationTable colgroup col:nth-child(n+5) {
            display: table-cell !important;
        }
      `;
      cookieDeclarationStyleSheet.appendChild(document.createTextNode(css));
      document.head.appendChild(cookieDeclarationStyleSheet);
    }
  }, []);

  return (
    <Container>
      {cookiesInUseHeadlineHtml !== emptyBody && (
        <Box
          textAlign='center'
          mb={{ base: 'm', md: 'l' }}
          textStyle='desktop.h4'
          dangerouslySetInnerHTML={{ __html: cookiesInUseHeadlineHtml }}
        />
      )}
      <Box id='CookieDeclaration' />
      <Script
        nonce={cookie.nonce}
        src={`https://consent.cookiebot.com/${domainGroupId}/cd.js`}
        type='text/javascript'
      />
    </Container>
  );
};
