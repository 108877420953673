
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    // import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ProdClient } from 'client/client';
import { ContentPage } from 'components/pages/ContentPage';
import { HeadComponent } from 'components/pages/Head';
import { getTranslations } from 'helpers/translations';
import { ISR_REVALIDATE_TIME } from 'lib/constants';
// import { createRedisInstance, EXPIRY_MS, MAX_AGE } from 'lib/redis/redis';
import cacheData from 'memory-cache';
import { CampaignPageContent, ContentPage as ContentPageModel, Redirect as RedirectModel } from 'models';
import { GetStaticPaths, GetStaticProps, GetStaticPropsContext } from 'next';
import React from 'react';
import safeJsonStringify from 'safe-json-stringify';
import { Translations } from 'store/translations';
import { GetContentPage } from 'utils/sitemap';
// import { appInsights } from '../appInsights';
import { getLocaleVariants } from './api/locale';
import { normalize } from './api/sitemap';
// Redirect as RedirectModel
const uniqueSlugCheck = (page: ContentPageModel, slugs: string[], translations: Translations) => {
    const realUrl = Object.keys(normalize(page, undefined, undefined, true, translations))[0];
    if (realUrl !== `/${slugs.join('/')}`)
        return {
            redirect: {
                destination: realUrl ?? '/',
                permanent: true,
            },
        };
};
export type ContentViewProps = {
    model: ContentPageModel;
};
export const ContentView = ({ model }: ContentViewProps) => {
    return (<>
      {model && <HeadComponent page={model}/>}
      {model && <ContentPage key={model.system.id} content={model}/>}
    </>);
};
export default ContentView;
export const getStaticPaths: GetStaticPaths = () => {
    return {
        paths: [],
        fallback: true,
    };
};
const getStaticProps: GetStaticProps<ContentViewProps> = async (ctx) => {
    return await _getDynamicPage(ctx);
};
export const _getDynamicPage = async (ctx: GetStaticPropsContext) => {
    const languageCodename = ctx.locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
    const slugs = ctx.params?.slug ?? (ctx.params ? Object.values(ctx.params) : undefined);
    const lastSlug = slugs && (slugs[slugs.length - 1] as string);
    const slugCheckPage = lastSlug && (await GetContentPage.bySlug(languageCodename, lastSlug, false));
    const relativeUrl = (ctx.locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE ? '/' : `/${ctx.locale}/`) +
        Object.values(ctx.params!).join('/').toLowerCase();
    cacheData.put('language', languageCodename);
    // console.log('slugCheckPage',slugCheckPage)
    // appInsights.trackTrace({ message: `Last slug codename - ${lastSlug}` });
    // const appInsights = new ApplicationInsights({
    //   config: {
    //     connectionString:
    //       'InstrumentationKey=d3cd31c8-b3ef-4b22-8d7a-edccf8e844fa;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/',
    //     /* ...Other Configuration Options... */
    //     enableRequestHeaderTracking: true,
    //   },
    // });
    // appInsights.loadAppInsights();
    // const redis = await createRedisInstance();
    // const key = 'redirectScheme';
    // const cached = await redis?.get(key);
    // if (cached) {
    //   const data = JSON.parse(cached);
    //   console.log('Loading', data?.items.length, 'redirects from cache!');
    //   // res.status(200).json({ result: data });
    //   await redis?.quit();
    //   // return;
    // }
    if (cacheData.get('redirectScheme') == null) {
        await ProdClient.items<RedirectModel>()
            .type('redirect')
            .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
            .toPromise()
            .then((res) => {
            // await redis?.set(key, JSON.stringify(res.data), EXPIRY_MS, MAX_AGE);
            res.data.items,
                cacheData.put('redirectScheme', res.data.items, 86400000, function () {
                    console.log('clearing the cache');
                    cacheData.del('redirectScheme'); //to clear the existing cache after 24hr
                });
        })
            .catch(() => null);
    }
    if (ctx.params) {
        const redirectCache = cacheData.get('redirectScheme') &&
            (cacheData.get('redirectScheme').find((item) => item.elements.source.value === relativeUrl) as RedirectModel);
        if (redirectCache) {
            return {
                redirect: {
                    destination: redirectCache.elements.destination.value,
                    permanent: redirectCache.elements.permanent.value[0]?.codename === 'yes' ? true : false,
                },
            };
        }
    }
    if (!slugCheckPage) {
        // appInsights.trackTrace({ message: `Page not found for - ${lastSlug}` });
        return {
            redirect: {
                destination: `${languageCodename === process.env.NEXT_PUBLIC_DEFAULT_LOCALE! ? '' : `/${languageCodename}`}/404?lastSlug=${lastSlug}`,
                permanent: false,
            },
        };
    }
    const translations = await getTranslations(languageCodename);
    const redirectIfNotUniqueSlug = uniqueSlugCheck(slugCheckPage, slugs as string[], translations);
    if (redirectIfNotUniqueSlug)
        return redirectIfNotUniqueSlug;
    const page = await GetContentPage.bySlug(languageCodename, lastSlug, true);
    const navigationCodename = page?.elements.category.value[0]?.codename === 'campaign' ||
        page?.elements.category.value[0]?.codename === 'annual_report'
        ? (page.elements.content.linkedItems[0] as CampaignPageContent).elements.navigation.linkedItems[0]?.system
            .codename
        : undefined;
    const footerCodename = page?.elements.category.value[0]?.codename === 'campaign' ||
        page?.elements.category.value[0]?.codename === 'annual_report'
        ? (page.elements.content.linkedItems[0] as CampaignPageContent).elements.footer.linkedItems[0]?.system.codename
        : undefined;
    const partOfCampaignCodename = (page?.elements.partOfCampaignPage.linkedItems[0]?.elements.category.value[0].codename.includes('campaign') &&
        page.elements.partOfCampaignPage.linkedItems[0].system.codename) ||
        undefined;
    const variants = lastSlug?.length && !lastSlug.includes('_')
        ? ((await getLocaleVariants(lastSlug, languageCodename, true)) as {
            [key: string]: string;
        })
        : undefined;
    return {
        props: JSON.parse(safeJsonStringify({
            model: page,
            layout: { footerCodename, navigationCodename, partOfCampaignCodename },
            variants,
        })) as ContentViewProps,
        revalidate: ISR_REVALIDATE_TIME,
    };
};

    async function __Next_Translate__getStaticProps__1931aad5a08__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[...slug]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1931aad5a08__ as getStaticProps }
  