import { Flex, StackItem, VStack } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { GlanceAndText, hasAtAGlance } from 'components/combination/glanceAndText';
import { CookieDeclaration } from 'components/modules/CookieDeclaration';
import { OverviewBlock } from 'components/modules/OverviewBlock';
import { OverviewSpotAutomatic } from 'components/modules/OverviewSpotAutomatic';
import { toTitleCase } from 'helpers/textCase';
import {
  CampaignPageContent,
  CareersAboutPageContent,
  ContentPage as ContentPageModel,
  CustomFormPageContent,
  InsightsPageContent,
  LegalPageContent,
  NewsPageContent,
  OfficePageContent,
  OverviewSpotAutomatic as OverviewSpotAutomaticModel,
  OverviewSpotAutomaticNoTabs,
  ProductPageContent,
  ProjectPageContent,
  SectorPageContent,
  ServiceLinePageContent,
  ServicePageContent,
  TextModule as TextModuleModel,
  UnifyingThemePageContent,
} from 'models';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { useCookies } from 'react-cookie';
// import slugify from 'slugify';
import { withTranslations } from 'store/translations';
import { ContentType, mapper } from 'utils/componentMapper';
import { hrefLink } from 'utils/normalization/hrefLink';

import Breadcrumb, { BackLink } from '../base/Breadcrumb';

type ContentPageProps = {
  content: ContentPageModel;
};

type LinkedItemType =
  | NewsPageContent
  | SectorPageContent
  | ServiceLinePageContent
  | LegalPageContent
  | OfficePageContent
  | ProductPageContent
  | ProjectPageContent
  | ServicePageContent
  | CampaignPageContent
  | InsightsPageContent
  | CustomFormPageContent
  | CareersAboutPageContent
  | UnifyingThemePageContent;
export const ContentPage: FC<ContentPageProps> = ({ content }) => {
  //Special cases in Content Page
  //Used to determine when is the first time Text module shows, to add At A Glance to its side
  const pageContent = (content.elements.content.linkedItems[0] as LinkedItemType)?.elements.pageContent.linkedItems;
  const firstTextModuleIndex = pageContent?.map((element) => element.system.type).indexOf('text');
  const atAGlanceModel = hasAtAGlance(content);
  const router = useRouter();
  const translations = withTranslations();
  const [_cookie, setCookie] = useCookies();
  React.useEffect(() => {
    if (content.elements.tagsSector.value.length > 0) {
      const tagname = translations.sector(content.elements.tagsSector.value[0]?.codename);
      setCookie('breadcrumb_name', tagname);
    }
    if (content.elements.tagsThemeTopic.value.length > 0) {
      const tagname = translations.theme_topic(content.elements.tagsThemeTopic.value[0]?.codename);
      setCookie('breadcrumb_name', tagname);
    }
  }, [router.isReady, translations.isReady]);

  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;
  const breadcrumbOverride = content.elements.partOfCampaignPage.linkedItems.length
    ? ({
        href: hrefLink(
          content.elements.partOfCampaignPage.linkedItems[0],
          (slug as unknown as string)?.split('/'),
          true,
          router.locale,
          translations,
        ),
        label: content.elements.partOfCampaignPage.linkedItems[0].elements.summaryTitle.value,
      } as BackLink)
    : undefined;
  const overviewLink =
    // content.elements.category.value[0]?.codename == 'product' ||
    content.elements.category.value[0]?.codename == 'project' ||
    content.elements.category.value[0]?.codename == 'insight'
      ? ({
          href: `/${translations.multiple(content.elements.category.value[0]?.codename)}`,
          label: toTitleCase(translations.multiple(content.elements.category.value[0]?.codename)),
        } as BackLink)
      : content.elements.category.value[0]?.codename == 'product'
      ? ({
          href: `/${translations.multiple(content.elements.category.value[0]?.codename)}`,
          label: toTitleCase(translations.multiple(content.elements.category.value[0]?.codename)),
        } as BackLink)
      : undefined;

  const isLegalPage = content.elements.content.linkedItems[0]?.system.type === 'legal_page_content';
  // breadcrumbOverride ? setCookie("breadcrumb_path",breadcrumbOverride) : setCookie("breadcrumb_path",router.asPath)
  const cookiesInUseHeadlineHtml =
    isLegalPage &&
    createRichTextHtmlResolver(nodeParser).resolveRichText({
      element: (content.elements.content.linkedItems[0] as LegalPageContent).elements.cookiesInUseHeadline,
    }).html;
  return (
    <>
      {/*---Breadcrumb---*/}
      {!content.elements.hero?.linkedItems.length && breadcrumbOverride != undefined && breadcrumbOverride.href !== '' && (
        <Flex m={'40px'}>
          <Breadcrumb color='mountain.base' customLink={breadcrumbOverride} />
        </Flex>
      )}

      {content && (
        <VStack shouldWrapChildren spacing={0}>
          {/* --- Hero ---*/}
          {content.elements.hero.linkedItems.map((element, index) => {
            return (
              <StackItem key={index} display='block'>
                {mapper(content.elements.hero.linkedItems[0].system.type as ContentType) &&
                  React.createElement(mapper(content.elements.hero.linkedItems[0].system.type as ContentType) as any, {
                    model: element,
                    customBreadcrumb: overviewLink,
                  })}
              </StackItem>
            );
          })}
          {/* --- Page Content ---*/}
          {pageContent?.map((element, index) => {
            if (!Object.values<string>(ContentType).includes(element.system.type))
              return (
                <StackItem key={index} backgroundColor={'cyan.20'} textStyle={'desktop.h5'} p='s'>
                  Component {element.system.type} is not implemented yet.
                </StackItem>
              );
            const type = element.system.type as ContentType;
            {
              /* At a Glance Condition */
            }
            if (type === ContentType.text && atAGlanceModel && index === firstTextModuleIndex)
              return (
                <StackItem key={index} display='block'>
                  <GlanceAndText textModuleModel={element as TextModuleModel} atAGlanceModel={atAGlanceModel} />
                </StackItem>
              );
            {
              /* Overview Block Condition */
            }
            if (type === ContentType.overview || type === ContentType.overview_spot___automatic) {
              if (type === ContentType.overview) {
                return (
                  <StackItem key={index} display='block'>
                    <OverviewBlock
                      model={element as OverviewSpotAutomaticNoTabs}
                      parentPageCodename={content.system.codename}
                    />
                  </StackItem>
                );
              }
              if (type === ContentType.overview_spot___automatic) {
                return (
                  <StackItem key={index} display='block'>
                    <OverviewSpotAutomatic
                      model={element as OverviewSpotAutomaticModel}
                      parentPageCodename={content.system.codename}
                    />
                  </StackItem>
                );
              }
            }

            if (type === ContentType.cookie_bot && cookiesInUseHeadlineHtml) {
              return (
                <StackItem key={index} display='block'>
                  <CookieDeclaration
                    domainGroupId={element.elements.domainGroupId.value}
                    cookiesInUseHeadlineHtml={cookiesInUseHeadlineHtml}
                  />
                </StackItem>
              );
            }
            return (
              <StackItem key={index} display='block'>
                {mapper(type) && React.createElement(mapper(type) as any, { model: element })}
              </StackItem>
            );
          })}
        </VStack>
      )}
    </>
  );
};
